<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-col
    cols="12"
    elevation="15"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-row
      dense
      justify="center"
    >
      <v-col
        align-self="center"
        cols="auto"
      >
        <v-img
          :src="AppLogo"
          contain
          height="50"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

export default {
  name: 'AuthLogo'
}
</script>
